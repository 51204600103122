module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DoingThings","short_name":"DoingThings","start_url":"/","background_color":"#e1fa00","theme_color":"#e1fa00","display":"fullscreen","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d58597632c75edf7a36a4ee1493e2354"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"doing-things","accessToken":"MC5ad1JLOFJFQUFDd0Fiazlv.77-977-9JWHvv73vv73vv73vv73vv73vv71BGgxoYkIRPzzvv71_Bgjvv73vv73vv73vv71CRTDvv71_"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
