import React from "react"
import Icon from "../Icon"
import * as styles from "./brand-card.module.css"

const BrandCard = ({ brand }) => {
  const socialLinks = Object.entries(brand.socialLinks) || []

  return (
    <div className={styles.wrapper} style={{ "--bgColor": brand.bgColor }}>
      <div className={styles.imageWrapper}>
        <img src={brand.logo} alt={brand.name} />
      </div>
      <div className={styles.info} data-color-scheme="scheme-5">
        <p>{brand.name}</p>
        {brand.storeLink && (
          <a href={brand.storeLink} className={styles.storeLink} target="_blank" rel="noreferrer">Shop <Icon name="external" size=".8em"/></a>
        )}
        {socialLinks.length > 0 && (
          <ul>
            {socialLinks.map(([platform, link]) => (
              <li key={link}>
                <a href={link} target="_blank" rel="noreferrer">
                  <Icon name={platform} size="1.25em" />
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default BrandCard
