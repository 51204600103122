import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import Yeti from "../../components/Yeti"
import * as styles from "./hero.module.css"

export const Hero = ({ slice }) => {
  const { heading } = slice?.primary || {}

  return (
    <section className={styles.hero} data-color-scheme="scheme-3">
      <div className={styles.background}>
        <div className={styles.scrollWrapper}>
          <div className={styles.scrollImageWrapper}>
            <img
              src="/images/hero-bg.jpg"
              loading="eager"
              alt=""
              width={1920}
              height={961}
            />
          </div>
          <div className={styles.scrollImageWrapper}>
            <img
              src="/images/hero-bg.jpg"
              loading="eager"
              alt=""
              width={1920}
              height={961}
            />
          </div>
        </div>
      </div>
      <Yeti />
      <div className={"page-width " + styles.content}>
        {heading && (
          <PrismicRichText
            field={slice.primary.heading.richText}
            components={{
              heading1: ({ children }) => <h1 className="h0">{children}</h1>,
            }}
          />
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHero on PrismicHomepageDataBodyHero {
    primary {
      heading {
        richText
      }
    }
  }
`
