import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { SliceZone } from "@prismicio/react"
import Seo from "../components/Seo"

import Layout from "../components/Layout"
import { components } from "../slices"

const HomepageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const topMenu = data.prismicTopMenu || {}
  const footerData = data.prismicFooter || {}

  return (
    <Layout topMenu={topMenu.data} footerData={footerData.data}>
      <SliceZone slices={homepage.data.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($id: String!) {
    prismicHomepage(id: { eq: $id }) {
      _previewable
      data {
        body {
          ... on PrismicSlice {
            id
            slice_type
            slice_label
          }
          ...HomepageDataBodyHero
          ...HomepageDataBodyIntro
          ...HomepageDataBodyBrands
          ...HomepageDataBodyFeaturedVideo
          ...HomepageDataBodyPress
          ...HomepageDataBodySocialVideos
          ...HomepageDataBodyPartners
          ...HomepageDataBodyEcommShowcase
          ...HomepageDataBodyRetail
          ...HomepageDataBodyCollabs
          ...HomepageDataBodyLicensed
          ...HomepageDataBodyAbout
          ...HomepageDataBodyStats
          ...HomepageDataBodyContact
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export const Head = () => (
  <>
    <Seo title="We make content that doesn't suck" />
    <link rel="preload" href="/images/hero-bg.jpg" as="image" key="heroImage" />
  </>
)

export default withPrismicPreview(HomepageTemplate)
