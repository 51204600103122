import React from "react"

const ActiveSlideContext = React.createContext()

// Returns the value passed to the Provider component below
export const useActiveSlide = () => {
  return React.useContext(ActiveSlideContext)
}

// Sets up the Provider element with the scoped state
export const ActiveSlideProvider = ({ children }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)

  return (
    <ActiveSlideContext.Provider value={{ activeIndex, setActiveIndex }}>
      {children}
    </ActiveSlideContext.Provider>
  )
}

// Wraps the passed Component with the Provider above
export const withActiveSlideProvider = Component => {
  return function WrappedComponent(props) {
    return (
      <ActiveSlideProvider>
        <Component {...props} />
      </ActiveSlideProvider>
    )
  }
}
