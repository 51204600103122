// Resolve different paths based on the Prismic doc type
exports.linkResolver = doc => {
  switch (doc.type) {
    case "homepage": {
      return "/"
    }

    default:
      return "/"
  }
}
