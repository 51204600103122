import React from "react"
import { Label } from "@radix-ui/react-label"
import Icon from "../Icon"
import FormSelect from "./FormSelect"
import * as styles from "./contact-form.module.css"
import LoadingSpinner from "../LoadingSpinner"

const subjects = [
  "Doing Things Partnerships",
  "Customer Support",
  "Advertisers",
  "Licensing",
  "Press",
  "Careers",
  "Other",
]

const ContactForm = ({ heading }) => {
  const [subject, setSubject] = React.useState(subjects[0])
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [formStatus, setFormStatus] = React.useState("idle")

  const subjectId = React.useId()
  const nameId = React.useId()
  const emailId = React.useId()
  const phoneId = React.useId()
  const messageId = React.useId()

  async function handleSubmit(e) {
    e.preventDefault()
    setFormStatus("loading")

    const data = {
      subject,
      name,
      email,
      phone,
      message,
    }

    try {
      const response = await fetch("./.netlify/functions/sendContactEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })

      if (response.ok) {
        setFormStatus("success")
      } else {
        console.error("Email sending failed", await response.text())
        setFormStatus("error")
      }
    } catch (error) {
      console.error("Error occurred while sending email:", error)
      setFormStatus("error")
    }
  }

  return (
    <div className={styles.container}>
      {formStatus === "success" && (
        <div className={styles.successWrapper}>
          <p className="h3">Message received.</p>
          <p className="text-body-sm">
            Thanks for getting in touch! We'll review your submission and get back as soon as we can.
          </p>
        </div>
      )}
      {formStatus === "loading" && (
        <div className={styles.loadingWrapper}>
          <LoadingSpinner />
        </div>
      )}
      {formStatus === "error" && (
        <div className={styles.errorWrapper}>
          <p className="h3">Failed to send message.</p>
          <p className="text-body-sm">Please try again later.</p>
        </div>
      )}
      {formStatus === "idle" && (
        <div>
          {heading && <h3 className="h4">{heading}</h3>}
          <form onSubmit={handleSubmit}>
            <Label htmlFor={subjectId}>
              <span className="visually-hidden">Subject</span>
            </Label>
            <FormSelect
              availableValues={subjects}
              value={subject}
              onValueChange={setSubject}
              id={subjectId}
            />
            <label htmlFor={nameId} className="visually-hidden">
              Name
            </label>
            <input
              type="text"
              placeholder="Your Name"
              value={name}
              id={nameId}
              name={nameId}
              required
              onChange={e => setName(e.target.value)}
            />
            <label htmlFor={emailId} className="visually-hidden">
              Email Address
            </label>
            <input
              type="email"
              pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}"
              placeholder="Your Email"
              value={email}
              id={emailId}
              name={emailId}
              required
              onChange={e => setEmail(e.target.value)}
            />
            <label htmlFor={phoneId} className="visually-hidden">
              Phone Number
            </label>
            <input
              type="tel"
              pattern="[0-9]*"
              placeholder="Your Phone"
              value={phone}
              id={phoneId}
              name={phoneId}
              inputMode="numeric"
              onChange={e => setPhone(e.target.value)}
            />
            <label htmlFor={messageId} className="visually-hidden">
              Message
            </label>
            <textarea
              placeholder="Message"
              id={messageId}
              name={messageId}
              value={message}
              required
              onChange={e => setMessage(e.target.value)}
            />
            <button type="submit">
              Slide into our DMs <Icon name="send" size="1.2em" />
            </button>
          </form>
        </div>
      )}
    </div>
  )
}

export default ContactForm
