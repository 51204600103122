import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { PrismicRichText } from "@prismicio/react"

import Layout from "../components/Layout"

const PolicyTemplate = ({ data }) => {
  if (!data) return null

  const { title, content } = data.prismicPolicyPage.data || {}
  const topMenu = data.prismicTopMenu || {}
  const footerData = data.prismicFooter || {}

  return (
    <Layout topMenu={topMenu.data} footerData={footerData.data}>
      <div className="page-width--narrow padding-section-vertical">
        {title && <h1 className="h2 text-center">{title.text}</h1>}
        {content && <PrismicRichText field={content.richText} />}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query policyPageQuery($id: String!) {
    prismicPolicyPage(id: { eq: $id }) {
      _previewable
      data {
        title {
          text
        }
        content {
          richText
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(PolicyTemplate)
