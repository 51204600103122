import React from "react"
import ReactPlayer from "react-player"
import VisuallyHidden from "../VisuallyHidden"
import Icon from "../Icon"
import { useActiveSlide } from "../../context/active-slide-context"
import * as styles from "./social-video.module.css"

// To do: add posters to videos

const SocialVideoWrapper = ({ children }) => {
  return <div className={styles.videoWrapper}>{children}</div>
}

const SocialVideo = ({ index, url, children }) => {
  const { activeIndex } = useActiveSlide()
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)
  const isActive = index === activeIndex

  React.useEffect(() => {
    if (!isActive && isPlaying) {
      setIsPlaying(false)
    }
  }, [isActive, isPlaying])

  const hasChildren = React.Children.count(children) > 0

  function handleInfoOpenClick() {
    setIsPlaying(false)
    setIsInfoOpen(true)
  }

  return (
    <div className={styles.wrapper}>
      <ReactPlayer
        url={url}
        playsinline
        wrapper={SocialVideoWrapper}
        playing={isPlaying}
      />
      <div className={styles.buttonsWrapper}>
        {hasChildren && (
          <button
            className={styles.controlButton}
            onClick={handleInfoOpenClick}
          >
            <Icon name="info" size="2rem" />
            <VisuallyHidden>Open video info</VisuallyHidden>
          </button>
        )}
        <button
          className={styles.controlButton}
          onClick={() => setIsPlaying(prevIsPlaying => !prevIsPlaying)}
        >
          {isPlaying ? <Icon name="pause" /> : <Icon name="play" />}
          <VisuallyHidden>{isPlaying ? "Pause" : "Play"} video</VisuallyHidden>
        </button>
      </div>
      {hasChildren && (
        <div className={styles.infoWrapper} aria-hidden={!isInfoOpen}>
          <div className={styles.infoInner}>
            {children}
            <button
              className={styles.controlButton}
              onClick={() => setIsInfoOpen(false)}
            >
              <Icon name="close" />
              <VisuallyHidden>Close video info</VisuallyHidden>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SocialVideo
