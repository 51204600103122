import { Hero } from "./Hero"
import { Intro } from "./Intro"
import { Brands } from "./Brands"
import { FeaturedVideo } from "./FeaturedVideo"
import { Press } from "./Press"
import { Partners } from "./Partners"
import { Retail } from "./Retail"
import { Licensed } from "./Licensed"
import { About } from "./About"
import { Stats } from "./Stats"
import { Contact } from "./Contact"
import { SocialVideos } from "./SocialVideos"
import { EcommShowcase } from "./EcommShowcase"
import { Collabs } from "./Collabs"

export const components = {
  hero: Hero,
  intro: Intro,
  brands: Brands,
  featured_video: FeaturedVideo,
  press: Press,
  partners: Partners,
  retail: Retail,
  licensed: Licensed,
  about: About,
  stats: Stats,
  contact: Contact,
  social_videos: SocialVideos,
  ecomm_showcase: EcommShowcase,
  collabs: Collabs,
}
