import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import * as styles from "./retail.module.css"

export const Retail = ({ slice }) => {
  const { heading, copy } = slice?.primary || {}

  return (
    <section data-color-scheme="scheme-3">
      <div className="section-split-6-5 page-width padding-section-vertical">
        <div className={styles.copy}>
          {heading && (
            <PrismicRichText
              field={heading.richText}
              components={{
                heading2: ({ children }) => <h2 className="h1">{children}</h2>,
              }}
            />
          )}
          {copy && <PrismicRichText field={copy.richText} />}
        </div>
        <div className={styles.retailers}>
          <ul className={styles.list}>
            {slice.items?.map(({ retailer_link, retailer_logo }) => (
              <li key={retailer_logo.url}>
                {retailer_link?.url ? (
                  <a href={retailer_link.url} target="_blank" rel="noreferrer">
                    <GatsbyImage
                      image={retailer_logo.gatsbyImageData}
                      alt={retailer_logo.alt}
                      objectFit="contain"
                      className={"company-logo " + styles.logo}
                    />
                  </a>
                ) : (
                  <GatsbyImage
                    image={retailer_logo.gatsbyImageData}
                    alt={retailer_logo.alt}
                    objectFit="contain"
                    className={"company-logo " + styles.logo}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyRetail on PrismicHomepageDataBodyRetail {
    primary {
      heading {
        richText
      }
      copy {
        richText
      }
    }
    items {
      retailer_link {
        url
      }
      retailer_logo {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
    }
  }
`
