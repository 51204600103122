import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import * as styles from "./intro.module.css"

export const Intro = ({ slice }) => {
  const { copy } = slice?.primary || {}

  return (
    <section data-color-scheme="scheme-4" id="intro">
      <div className="section-split-5-6 page-width padding-section-vertical">
        <div className={styles.content}>
          {copy && (
            <PrismicRichText
              field={copy.richText}
              components={{
                paragraph: ({ children }) => (
                  <p className="text-body-xl">{children}</p>
                ),
              }}
            />
          )}
        </div>
        <div>
          <div className={styles.statsWrapper + " grid"}>
            {slice.items?.map(({ stat_metric, stat_unit }) => (
              <div key={stat_metric.text} className={styles.stat}>
                <span className={styles.statMetric}>{stat_metric.text}</span>
                <span className={styles.statUnit}>{stat_unit.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyIntro on PrismicHomepageDataBodyIntro {
    primary {
      copy {
        richText
      }
    }
    items {
      stat_metric {
        text
      }
      stat_unit {
        text
      }
    }
  }
`
