import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import Yeti from "../../components/Yeti"
import * as styles from "./about.module.css"

export const About = ({ slice }) => {
  const { heading, copy } = slice?.primary || {}

  return (
    <section data-color-scheme="scheme-3">
      <div className={"section-split page-width " + styles.inner}>
        <div className={"padding-section-vertical " + styles.content}>
          {heading && (
            <PrismicRichText
              field={heading.richText}
              components={{
                heading2: ({ children }) => <h2 className="h4">{children}</h2>,
              }}
            />
          )}
          {copy && <PrismicRichText field={copy.richText} />}
        </div>
        <div className={styles.graphic}>
          <Yeti />
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyAbout on PrismicHomepageDataBodyAbout {
    primary {
      heading {
        richText
      }
      copy {
        richText
      }
    }
  }
`
