import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { useActiveSlide } from "../../context/active-slide-context"
import VisuallyHidden from "../VisuallyHidden"
import phoneMockup from "../../images/social-mockup.png"

import "swiper/css"
import * as styles from "./phone-carousel.module.css"
import Icon from "../Icon"

const PhoneCarousel = ({ children }) => {
  const { setActiveIndex } = useActiveSlide()
  const swiperRef = React.useRef(null)

  const swiperContainerStyles = {
    height: "96%",
    width: "92%",
    position: "absolute",
    bottom: "2%",
    top: "2%",
    left: "4%",
    right: "4%",
    borderRadius: "8%",
    backgroundColor: "black",
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Swiper
          onBeforeInit={swiper => {
            swiperRef.current = swiper
          }}
          onSlideChange={swiper => {
            setActiveIndex(swiper.activeIndex)
          }}
          speed={350}
          slidesPerView={1}
          slidesPerGroup={1}
          direction="vertical"
          rewind={true}
          style={swiperContainerStyles}
        >
          {React.Children.map(children, child => (
            <SwiperSlide>{child}</SwiperSlide>
          ))}
        </Swiper>
        <img
          loading="eager"
          src={phoneMockup}
          alt=""
          width={500}
          height={1019}
          className={styles.phone}
        />
      </div>
      {children.length > 1 && (<>
        <button
          onClick={() => swiperRef.current?.slidePrev()}
          className={styles.navButton}
          data-direction="previous"
        >
          <Icon name="chevron" size="2.8rem" />
          <VisuallyHidden>Navigate to previous slide</VisuallyHidden>
        </button>
        <button
          onClick={() => swiperRef.current?.slideNext()}
          className={styles.navButton}
          data-direction="next"
        >
          <Icon name="chevron" size="2.8rem" />
          <VisuallyHidden>Navigate to next slide</VisuallyHidden>
        </button>
      </>)}
    </div>
  )
}

export default PhoneCarousel
