import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import * as styles from "./stats.module.css"

export const Stats = ({ slice }) => {
  const { heading } = slice?.primary || {}

  return (
    <section>
      <div className="section-split-5-6 page-width padding-section-vertical">
        <div>
          {heading && (
            <PrismicRichText
              field={heading.richText}
              components={{
                heading2: ({ children }) => <h2 className="h2">{children}</h2>,
              }}
            />
          )}
        </div>
        <div>
          <div className={styles.statsWrapper + " grid"}>
            {slice.items?.map(({ stat_metric, stat_unit }) => (
              <div key={stat_metric.text} className={styles.stat}>
                <span className={styles.statMetric}>{stat_metric.text}</span>
                <span className={styles.statUnit}>{stat_unit.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyStats on PrismicHomepageDataBodyStats {
    primary {
      heading {
        richText
      }
    }
    items {
      stat_metric {
        text
      }
      stat_unit {
        text
      }
    }
  }
`
