import { linkResolver } from "./linkResolver"

import PolicyTemplate from "../templates/policy"
import HomepageTemplate from "../templates/homepage"

export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      policy_page: PolicyTemplate,
      homepage: HomepageTemplate,
    },
  },
]
