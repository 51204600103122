import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import ContactForm from "../../components/ContactForm"
import * as styles from "./contact.module.css"

export const Contact = ({ slice }) => {
  const { heading, copy, form_heading } = slice?.primary || {}

  return (
    <section data-color-scheme="scheme-2-alt" id="contact">
      <div className="section-split-6-5 page-width padding-section-vertical">
        <div className={styles.content}>
          {heading && (
            <PrismicRichText
              field={heading.richText}
              components={{
                heading2: ({ children }) => <h2 className="h1">{children}</h2>,
              }}
            />
          )}
          {copy && <PrismicRichText field={copy.richText} />}
        </div>
        <div className={styles.form}>
          <ContactForm heading={form_heading?.text} />
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyContact on PrismicHomepageDataBodyContact {
    primary {
      heading {
        richText
      }
      copy {
        richText
      }
      form_heading {
        text
      }
    }
  }
`
