import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import VisuallyHidden from "../VisuallyHidden"
import Icon from "../Icon"
import * as styles from "./social-image.module.css"

const SocialImage = ({ image, alt, children }) => {
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)

  const hasChildren = React.Children.count(children) > 0

  return (
    <div className={styles.wrapper}>
      <GatsbyImage image={image} alt={alt} />
      {hasChildren && (
        <div className={styles.buttonsWrapper}>
          <button
            className={styles.controlButton}
            onClick={() => setIsInfoOpen(true)}
          >
            <Icon name="info" size="2rem" />
            <VisuallyHidden>Open image info</VisuallyHidden>
          </button>
        </div>
      )}
      {hasChildren && (
        <div className={styles.infoWrapper} aria-hidden={!isInfoOpen}>
          <div className={styles.infoInner}>
            {children}
            <button
              className={styles.controlButton}
              onClick={() => setIsInfoOpen(false)}
            >
              <Icon name="close" />
              <VisuallyHidden>Close image info</VisuallyHidden>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SocialImage
