import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import * as styles from "./licensed.module.css"
import { GatsbyImage } from "gatsby-plugin-image"

export const Licensed = ({ slice }) => {
  const { heading, copy, featured_image } = slice?.primary || {}

  return (
    <section className={styles.container} data-color-scheme="scheme-4-alt">
      <div className={styles.graphic}>
        <GatsbyImage
          image={featured_image.gatsbyImageData}
          alt={featured_image.alt}
        />
      </div>
      <div className={styles.content}>
        {heading && (
          <PrismicRichText
            field={heading.richText}
            components={{
              heading2: ({ children }) => <h2 className="h1">{children}</h2>,
            }}
          />
        )}
        {copy && <PrismicRichText field={copy.richText} />}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyLicensed on PrismicHomepageDataBodyLicensed {
    primary {
      heading {
        richText
      }
      copy {
        richText
      }
      featured_image {
        gatsbyImageData(placeholder: BLURRED)
        alt
      }
    }
  }
`
