import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Logo from "../Logo"
import VisuallyHidden from "../VisuallyHidden"
import FancyLink from "../FancyLink"
import * as styles from "./header.module.css"
import Yeti from "../Yeti"

export const Header = ({ topMenu }) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false)

  function handleNavToggleClick(e) {
    e.preventDefault()
    setIsMobileNavOpen(prevState => !prevState)
  }

  const { menu_links, button_label, button_link } = topMenu

  const menuLinks = menu_links
    ? menu_links.map((menuLink, index) => (
        <li key={`top-nav-${index}`}>
          <FancyLink href={menuLink.link.url} target={menuLink.link.target}>
            {menuLink.label.text}
          </FancyLink>
        </li>
      ))
    : null

  React.useEffect(() => {
    if (isMobileNavOpen) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.contains("overflow-hidden") &&
        document.body.classList.remove("overflow-hidden")
    }
  }, [isMobileNavOpen])

  return (
    <header className={styles.container} data-menu-open={isMobileNavOpen}>
      <div className={"page-width " + styles.wrapper}>
        <Link to="/" className={styles.logo}>
          <span className="visually-hidden">Navigate to home page</span>
          <Logo alt="Doing Things" />
        </Link>
        {menuLinks && (
          <nav className={styles.nav} id="nav">
            <ul>{menuLinks}</ul>
          </nav>
        )}
        <button
          className={styles.navToggle}
          aria-expanded={isMobileNavOpen}
          aria-controls="nav"
          onClick={handleNavToggleClick}
        >
          <span aria-hidden="true"></span>
          <VisuallyHidden>
            {(isMobileNavOpen ? "Close" : "Open") + " navigation"}
          </VisuallyHidden>
        </button>
        {button_label && (
          <a
            className={styles.navBtn}
            href={button_link.url || "/#contact"}
            target={button_link.target}
          >
            <span>{button_label.text}</span>
            <Yeti />
          </a>
        )}
      </div>
    </header>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    data {
      menu_links {
        label {
          text
        }
        link {
          id
          url
          target
        }
      }
      button_label {
        text
      }
      button_link {
        url
        target
      }
    }
  }
`
