import React from "react"
import { Link } from "gatsby"
import { PrismicProvider } from "@prismicio/react"

import { linkResolver } from "./src/utils/linkResolver"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import { repositoryConfigs } from "./src/utils/prismicPreviews"

import "./src/styles/global.css"

// Wrap the root element with the Prismic provider
export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      {element}
    </PrismicPreviewProvider>
  </PrismicProvider>
)
