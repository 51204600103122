import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player/lazy"
import Icon from "../../components/Icon"
import VisuallyHidden from "../../components/VisuallyHidden"
import * as styles from "./featured-video.module.css"

const ReactPlayerWrapper = ({ children }) => {
  return <div style={{ width: "100%", height: "100%" }}>{children}</div>
}

export const FeaturedVideo = ({ slice }) => {
  const [isPlaying, setIsPlaying] = React.useState(false)
  const { heading, video_poster, video_link } = slice?.primary || {}

  return (
    <section
      className={styles.container}
      data-color-scheme="scheme-3"
      data-video-playing={isPlaying}
    >
      <div className={"section-split-6-5 page-width " + styles.content}>
        {heading && (
          <PrismicRichText
            field={heading.richText}
            components={{
              heading2: ({ children }) => <h2 className="h0">{children}</h2>,
            }}
          />
        )}
        <button
          className={styles.playButton}
          onClick={() => setIsPlaying(true)}
        >
          <Icon name="play" size="var(--icon-size, 24rem)" />
          <VisuallyHidden>Play video</VisuallyHidden>
        </button>
      </div>
      <div className={styles.videoWrapper}>
        <div className={styles.videoWrapperInner}>
          {video_link && (
            <ReactPlayer
              url={video_link.url}
              style={{ width: "100%", height: "100%" }}
              wrapper={ReactPlayerWrapper}
              playing={isPlaying}
              controls={true}
              onEnded={() => setIsPlaying(false)}
              onPause={() => setIsPlaying(false)}
            />
          )}
          {video_poster && (
            <GatsbyImage
              image={video_poster.gatsbyImageData}
              alt=""
              className={styles.poster}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeaturedVideo on PrismicHomepageDataBodyFeaturedVideo {
    primary {
      heading {
        richText
      }
      video_poster {
        gatsbyImageData(placeholder: BLURRED)
      }
      video_link {
        url
      }
    }
  }
`
