import React from "react"
import * as styles from "./fancy-link.module.css"

const FancyLink = ({ href, target = null, children }) => {
  return (
    <a href={href} className={styles.fancyLink} target={target}>
      <span>{children}</span>
      <span aria-hidden="true">{children}</span>
    </a>
  )
}

export default FancyLink
