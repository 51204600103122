import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import * as styles from "./partners.module.css"

export const Partners = ({ slice }) => {
  const { heading } = slice?.primary || {}

  return (
    <section data-color-scheme="scheme-4" className={styles.section}>
      <div className={"page-width padding-section-vertical " + styles.content}>
        {heading && (
          <PrismicRichText
            field={heading.richText}
            components={{
              heading2: ({ children }) => <h2 className="h2">{children}</h2>,
            }}
          />
        )}
        <ul className={"flex-grid " + styles.list}>
          {slice.items?.map(({ partner_link, partner_logo }) => (
            <li key={partner_logo.url}>
              {partner_link?.url ? (
                <a href={partner_link.url} target="_blank" rel="noreferrer">
                  <GatsbyImage
                    image={partner_logo.gatsbyImageData}
                    alt={partner_logo.alt}
                    objectFit="contain"
                    className={"company-logo " + styles.logo}
                  />
                </a>
              ) : (
                <GatsbyImage
                  image={partner_logo.gatsbyImageData}
                  alt={partner_logo.alt}
                  objectFit="contain"
                  className={"company-logo " + styles.logo}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPartners on PrismicHomepageDataBodyPartners {
    primary {
      heading {
        richText
      }
    }
    items {
      partner_link {
        url
      }
      partner_logo {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
    }
  }
`
