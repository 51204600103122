import React from "react"

import { Header } from "../Header"
import { Footer } from "../Footer"
import SkipToContent from "../SkipToContent"

const Layout = ({ topMenu, footerData, children }) => {
  return (
    <>
      <SkipToContent />
      <Header topMenu={topMenu} />
      <main id="main">{children}</main>
      <Footer footerData={footerData} />
    </>
  )
}

export default Layout
