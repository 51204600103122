import React from "react"

const Icon = ({ name, size = "1em" }) => {
  const styles = {
    height: size,
    width: size,
  }

  switch (name) {
    /* Instagram */
    case "instagram":
      return (
        <svg viewBox="0 0 24 24" style={styles}>
          <path
            fill="currentColor"
            d="m12.004 5.838c-3.403 0-6.158 2.758-6.158 6.158 0 3.403 2.758 6.158 6.158 6.158 3.403 0 6.158-2.758 6.158-6.158 0-3.403-2.758-6.158-6.158-6.158zm0 10.155c-2.209 0-3.997-1.789-3.997-3.997s1.789-3.997 3.997-3.997 3.997 1.789 3.997 3.997c.001 2.208-1.788 3.997-3.997 3.997z"
          ></path>
          <path
            fill="currentColor"
            d="m16.948.076c-2.208-.103-7.677-.098-9.887 0-1.942.091-3.655.56-5.036 1.941-2.308 2.308-2.013 5.418-2.013 9.979 0 4.668-.26 7.706 2.013 9.979 2.317 2.316 5.472 2.013 9.979 2.013 4.624 0 6.22.003 7.855-.63 2.223-.863 3.901-2.85 4.065-6.419.104-2.209.098-7.677 0-9.887-.198-4.213-2.459-6.768-6.976-6.976zm3.495 20.372c-1.513 1.513-3.612 1.378-8.468 1.378-5 0-7.005.074-8.468-1.393-1.685-1.677-1.38-4.37-1.38-8.453 0-5.525-.567-9.504 4.978-9.788 1.274-.045 1.649-.06 4.856-.06l.045.03c5.329 0 9.51-.558 9.761 4.986.057 1.265.07 1.645.07 4.847-.001 4.942.093 6.959-1.394 8.453z"
          ></path>
          <circle fill="currentColor" cx="18.406" cy="5.595" r="1.439"></circle>
        </svg>
      )

    /* LinkedIn */
    case "linkedin":
      return (
        <svg viewBox="0 0 510 510" style={styles}>
          <path
            fill="currentColor"
            d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0zM153,433.5H76.5V204H153V433.5z M114.75,160.65c-25.5,0-45.9-20.4-45.9-45.9s20.4-45.9,45.9-45.9s45.9,20.4,45.9,45.9S140.25,160.65,114.75,160.65z M433.5,433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25,17.851-38.25,38.25V433.5H204V204h76.5v30.6c12.75-20.4,40.8-35.7,63.75-35.7c48.45,0,89.25,40.8,89.25,89.25V433.5z"
          ></path>
        </svg>
      )

    /* Twitter */
    case "twitter":
      return (
        <svg viewBox="0 0 1226.37 1226.37" style={styles}>
          <path
            fill="currentColor"
            d="m727.348 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"
          ></path>
        </svg>
      )

    /* YouTube */
    case "youtube":
      return (
        <svg viewBox="-21 -117 682.66672 682" style={styles}>
          <path
            fill="currentColor"
            d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"
          ></path>
        </svg>
      )

    /* TikTok */
    case "tiktok":
      return (
        <svg viewBox="0 0 512 512" style={styles}>
          <path
            fill="currentColor"
            d="m480.32 128.39c-29.22 0-56.18-9.68-77.83-26.01-24.83-18.72-42.67-46.18-48.97-77.83-1.56-7.82-2.4-15.89-2.48-24.16h-83.47v228.08l-.1 124.93c0 33.4-21.75 61.72-51.9 71.68-8.75 2.89-18.2 4.26-28.04 3.72-12.56-.69-24.33-4.48-34.56-10.6-21.77-13.02-36.53-36.64-36.93-63.66-.63-42.23 33.51-76.66 75.71-76.66 8.33 0 16.33 1.36 23.82 3.83v-62.34-22.41c-7.9-1.17-15.94-1.78-24.07-1.78-46.19 0-89.39 19.2-120.27 53.79-23.34 26.14-37.34 59.49-39.5 94.46-2.83 45.94 13.98 89.61 46.58 121.83 4.79 4.73 9.82 9.12 15.08 13.17 27.95 21.51 62.12 33.17 98.11 33.17 8.13 0 16.17-.6 24.07-1.77 33.62-4.98 64.64-20.37 89.12-44.57 30.08-29.73 46.7-69.2 46.88-111.21l-.43-186.56c14.35 11.07 30.04 20.23 46.88 27.34 26.19 11.05 53.96 16.65 82.54 16.64v-60.61-22.49c.02.02-.22.02-.24.02z"
          ></path>
        </svg>
      )

    /* Facebook */
    case "facebook":
      return (
        <svg viewBox="-21 -21 682.66669 682.66669" style={styles}>
          <path
            fill="currentColor"
            d="m604.671875 0h-569.375c-19.496094.0117188-35.30078125 15.824219-35.296875 35.328125v569.375c.0117188 19.496094 15.824219 35.300781 35.328125 35.296875h306.546875v-247.5h-83.125v-96.875h83.125v-71.292969c0-82.675781 50.472656-127.675781 124.222656-127.675781 35.324219 0 65.679688 2.632812 74.527344 3.808594v86.410156h-50.855469c-40.125 0-47.894531 19.066406-47.894531 47.050781v61.699219h95.9375l-12.5 96.875h-83.4375v247.5h162.796875c19.507813.003906 35.324219-15.804688 35.328125-35.3125 0-.003906 0-.007812 0-.015625v-569.375c-.007812-19.496094-15.824219-35.30078125-35.328125-35.296875zm0 0"
          ></path>
        </svg>
      )

    /* Send */
    case "send":
      return (
        <svg viewBox="0 0 24 24" style={styles}>
          <path
            fill="currentColor"
            clipRule="evenodd"
            d="m3.53299 21.8848c-.86178.4308-1.80367-.4214-1.46066-1.3216l3.26284-8.5632-3.26285-8.56321c-.343-.90019.59889-1.75241 1.46067-1.32161l17.88261 8.93942c.7792.3895.7792 1.5012 0 1.8907zm14.45901-8.8848-13.71397 6.3985 2.74278-6.3985zm2.008-.9369.1353-.0631-.1353-.0631zm-2.008-1.0631h-10.97119l-2.74278-6.39851z"
            fillRule="evenodd"
          ></path>
        </svg>
      )

    /* Chevron */
    case "chevron":
      return (
        <svg viewBox="0 0 32 32" style={styles}>
          <path
            fill="currentColor"
            clipRule="evenodd"
            d="m4.93934 10.9393c.58579-.5857 1.53553-.5857 2.12132 0l8.93934 8.9394 8.9393-8.9394c.5858-.5857 1.5356-.5857 2.1214 0 .5857.5858.5857 1.5356 0 2.1214l-10 10c-.5858.5857-1.5356.5857-2.1214 0l-9.99996-10c-.58579-.5858-.58579-1.5356 0-2.1214z"
            fillRule="evenodd"
          ></path>
        </svg>
      )

    /* Pause */
    case "pause":
      return (
        <svg viewBox="0 0 13.221 13.221" style={styles}>
          <path
            fill="currentColor"
            d="M3.756,0H1.523C1.029,0,0.629,0.4,0.629,0.894v11.434c0,0.493,0.4,0.893,0.894,0.893h2.233c0.494,0,0.894-0.399,0.894-0.893V0.894C4.651,0.4,4.251,0,3.756,0z"
          ></path>
          <path
            fill="currentColor"
            d="M11.698,0H9.464C8.971,0,8.57,0.4,8.57,0.894v11.434c0,0.493,0.4,0.893,0.894,0.893h2.234c0.494,0,0.894-0.399,0.894-0.893V0.894C12.591,0.4,12.192,0,11.698,0z"
          ></path>
        </svg>
      )

    /* Play */
    case "play":
      return (
        <svg viewBox="0 0 163.861 163.861" style={styles}>
          <path
            fill="currentColor"
            d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"
          ></path>
        </svg>
      )

    /* Heart */
    case "heart":
      return (
        <svg viewBox="0 0 512 512" style={styles}>
          <path
            fill="currentColor"
            d="M376,30c-27.783,0-53.255,8.804-75.707,26.168c-21.525,16.647-35.856,37.85-44.293,53.268c-8.437-15.419-22.768-36.621-44.293-53.268C189.255,38.804,163.783,30,136,30C58.468,30,0,93.417,0,177.514c0,90.854,72.943,153.015,183.369,247.118c18.752,15.981,40.007,34.095,62.099,53.414C248.38,480.596,252.12,482,256,482s7.62-1.404,10.532-3.953c22.094-19.322,43.348-37.435,62.111-53.425C439.057,330.529,512,268.368,512,177.514C512,93.417,453.532,30,376,30z"
          ></path>
        </svg>
      )

    /* Speech Bubble */
    case "speechBubble":
      return (
        <svg viewBox="0 0 404.941 404.941" style={styles}>
          <path
            fill="currentColor"
            d="M391.689,18.283H13.25C5.931,18.283,0,24.218,0,31.535v242.48c0,7.32,5.931,13.252,13.25,13.252h202.674v86.137c0,5.359,3.228,10.193,8.18,12.244c1.64,0.68,3.361,1.01,5.068,1.01c3.446,0,6.838-1.348,9.372-3.883l96.171-95.508h56.975c7.32,0,13.252-5.932,13.252-13.252V31.534C404.943,24.217,399.01,18.283,391.689,18.283L391.689,18.283z"
          ></path>
        </svg>
      )

    /* Info */
    case "info":
      return (
        <svg viewBox="0 0 32 32" style={styles}>
          <path
            fill="currentColor"
            d="m12 6a4 4 0 1 1 4 4 4 4 0 0 1 -4-4zm5 6h-2a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3h2a3 3 0 0 0 3-3v-12a3 3 0 0 0 -3-3z"
          ></path>
        </svg>
      )

    /* Close */
    case "close":
      return (
        <svg viewBox="0 0 320.591 320.591" style={styles}>
          <path
            fill="currentColor"
            d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"
          ></path>
          <path
            fill="currentColor"
            d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"
          ></path>
        </svg>
      )

    /* External */
    case "external":
      return (
        <svg viewBox="0 0 512 512" style={styles}>
          <path
            fill="currentColor"
            d="m240.3 512h-153.5c-25.7 0-47.5-8.2-63-23.7s-23.7-37.4-23.7-63.2c-.1-111.2-.1-211.6 0-307 0-25.7 8.3-47.5 23.9-62.9s37.4-23.6 63.2-23.6h102c12.5 0 22.7 3.7 29.6 10.7 6.2 6.2 9.4 14.8 9.3 24.8-.2 21.2-15.2 34.8-38.4 34.9h-69.8-32.3c-14 0-17.3 3.3-17.3 17.5v304.8c0 14.1 3.3 17.3 17.4 17.3h152.4s101.6 0 152.4 0c14.1 0 17.4-3.2 17.4-17.4 0-16.7 0-33.4 0-50.1 0-17.3 0-34.6 0-51.9 0-23.2 13.8-38.3 35-38.4h.2c21.3 0 35.1 14.9 35.1 38v9.6c.1 31.3.1 63.7 0 95.5-.1 24.7-8.4 45.9-24 61.3s-36.8 23.6-61.6 23.7c-20.2 0-40.4 0-60.5 0h-93.8z"
          ></path>
          <path
            fill="currentColor"
            d="m221.9 322.8c-11.9 0-23.6-7.9-29.1-19.6-6.8-14.5-3.9-30.2 7.8-42.1 14.6-14.8 29.6-29.7 44.1-44.1l15.4-15.3 49.7-49.4c26.7-26.5 53.3-53 79.9-79.6.5-.5 1-1 1.5-1.6-16.2-.3-51.3-.5-70.8-.6-11.1 0-20.5-3.6-27.1-10.4-6.3-6.4-9.6-15.2-9.5-25.3.2-20.7 14.9-34.6 36.5-34.7 26.6-.1 53-.1 79.5-.1 26.9 0 51.8 0 76.2.1 21.7 0 35.8 13.9 35.9 35.3.2 53.5.2 106.5 0 157.7-.1 20.6-14.5 35.1-35 35.1s-34.9-14.4-35.1-34.9c-.1-10.5-.2-26.8-.3-42.6-.1-11.6-.1-22.7-.2-30.1-.8.7-1.5 1.4-2.2 2.1-15.4 15.5-30.8 30.9-46.2 46.4-45.4 45.6-92.3 92.8-138.7 138.9-7.2 7-16.2 11.9-25.9 14.2-2.2.4-4.3.6-6.4.6z"
          ></path>
        </svg>
      )

    default:
      return <p>No icon found.</p>
  }
}

export default Icon
