import React from "react"
import { graphql } from "gatsby"
import Icon from "../Icon"
import Logo from "../Logo"
import FancyLink from "../FancyLink"
import * as styles from "./footer.module.css"

export const Footer = ({ footerData }) => {
  const { tagline, contact_email, menu_links, social_links } = footerData

  const menuLinks = menu_links
    ? menu_links.map((menuLink, index) => {
        let link
        switch (menuLink.link.document?.type) {
          case "policy_page":
            link = `/legal/${menuLink.link.document.uid}`
            break
          case "page":
            link = `/${menuLink.link.document.uid}`
            break
          default:
            link = menuLink.link.url
        }

        return (
          <li key={`footer-nav-${index}`}>
            <FancyLink href={link} target={menuLink.link.target}>
              {menuLink.label.text}
            </FancyLink>
          </li>
        )
      })
    : null

  const socialLinks = social_links
    ? social_links.map((socialLink, index) => (
        <li key={`social-link-${index}`}>
          <a href={socialLink.link.url} target="_blanK" rel="noreferrer">
            <Icon name={socialLink.platform.toLowerCase()} size="2.4rem" />
          </a>
        </li>
      ))
    : null

  return (
    <footer className={styles.container}>
      <div className={"page-width " + styles.wrapper}>
        <div className={styles.heading}>
          {tagline && <p className="h3">{tagline.text}</p>}
          {contact_email && (
            <a href={"mailto:" + contact_email.text}>{contact_email.text}</a>
          )}
        </div>
        <nav className={styles.footerNav}>
          {menuLinks && <ul>{menuLinks}</ul>}
        </nav>
        {socialLinks && <ul className={styles.socialLinks}>{socialLinks}</ul>}
        <div className={styles.logo}>
          <Logo />
        </div>
        <small className={styles.copyright}>
          @{new Date().getFullYear()} Doing Things. All Rights Reserved. <br /> Website developed by <a title="Web Design in Knoxville, TN" href="https://makememodern.com" target="_blank">Make Me Modern</a>.
        </small>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    _previewable
    data {
      tagline {
        text
      }
      contact_email {
        text
      }
      menu_links {
        label {
          text
        }
        link {
          id
          target
          url
          document {
            ... on PrismicPolicyPage {
              type
              uid
            }
          }
        }
      }
      social_links {
        platform
        link {
          id
          url
        }
      }
    }
  }
`
