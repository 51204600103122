import mcfLogo from "./images/mcf-logo.png"
import breezyLogo from "./images/breezy-logo.png"
import shsLogo from "./images/shs-logo.png"
import ohLogo from "./images/oh-logo.png"
import ksLogo from "./images/ks-logo.png"
import recessLogo from "./images/recess-logo.png"
import animalsLogo from "./images/animals-logo.jpg"
import radDadLogo from "./images/rad-dad-logo.png"
import bdsLogo from "./images/bds-logo.jpg"
import buddhaLogo from "./images/buddha-logo.png"

export const BRANDS = [
  {
    name: "Middle Class Fancy",
    logo: mcfLogo,
    bgColor: "#227A62",
    strokeColor: "white",
    socialLinks: {
      instagram: "https://www.instagram.com/middleclassfancy/",
      twitter: "https://twitter.com/midclassfancy",
      facebook: "https://www.facebook.com/middleclassfancy/",
    },
    storeLink: "https://middleclassfancy.com/"
  },
  {
    name: "Breezy Golf",
    logo: breezyLogo,
    bgColor: "#1A375F",
    strokeColor: "white",
    socialLinks: {
      instagram: "https://www.instagram.com/breezygolf/",
      facebook: "https://www.facebook.com/Breezygolf/",
    },
    storeLink: "https://middleclassfancy.com/"
  },
  {
    name: "Shithead Steve",
    logo: shsLogo,
    bgColor: "white",
    strokeColor: "#333333",
    socialLinks: {
      instagram: "https://www.instagram.com/shitheadsteve/",
      twitter: "https://twitter.com/shitheadsteve__",
      tiktok: "https://www.tiktok.com/@shitheadsteve",
      facebook: "https://www.facebook.com/sh1theadsteve/",
    },
    storeLink: "https://shitheadsteve.com/"
  },
  {
    name: "Overheard",
    logo: ohLogo,
    bgColor: "#f3e1bb",
    strokeColor: "#333333",
    socialLinks: {
      instagram: "https://www.instagram.com/overheardla/",
      tiktok: "https://www.tiktok.com/@overheard",
    },
    storeLink: "https://overheardshop.com/"
  },
  {
    name: "Kook Slams",
    logo: ksLogo,
    bgColor: "#6ed7ff",
    strokeColor: "black",
    socialLinks: {
      instagram: "https://www.instagram.com/kookslams/",
      facebook: "https://www.facebook.com/kookslams/",
      tiktok: "https://www.tiktok.com/@kookslamsofficial",
    },
    storeLink: "https://kookslams.com/"
  },
  {
    name: "Recess Therapy",
    logo: recessLogo,
    bgColor: "white",
    strokeColor: "#8456a7",
    socialLinks: {
      youtube: "https://m.youtube.com/@recesstherapy",
      instagram: "https://www.instagram.com/recess_therapy/",
      tiktok: "https://www.tiktok.com/@recesstherapy",
      facebook: "https://www.facebook.com/recesstherapyshow/",
    },
    storeLink: "https://shoprecesstherapy.com/"
  },
  {
    name: "Animals Doing Things",
    logo: animalsLogo,
    bgColor: "#54b947",
    strokeColor: "white",
    socialLinks: {
      instagram: "https://www.instagram.com/animalsdoingthings/",
      tiktok: "https://www.tiktok.com/@animalsdoingthings",
      facebook: "https://www.facebook.com/animalsdoingthings/",
    },
  },
  {
    name: "Rad Dad",
    logo: radDadLogo,
    bgColor: "white",
    strokeColor: "#10D1D6",
    socialLinks: {
      instagram: "https://www.instagram.com/raddad/",
    },
  },
  {
    name: "Bob Does Sports",
    logo: bdsLogo,
    bgColor: "#2263aa",
    strokeColor: "#ff303a",
    socialLinks: {
      youtube: "https://www.youtube.com/@Bobdoessports",
      instagram: "https://www.instagram.com/bobdoessports/",
      twitter: "https://twitter.com/bobdoessports",
    },
  },
  {
    name: "Moist Buddha",
    logo: buddhaLogo,
    bgColor: "white",
    strokeColor: "#01aed6",
    socialLinks: {
      instagram: "https://www.instagram.com/moistbuddha/",
      facebook: "https://www.facebook.com/moistbuddha/",
    },
  },
]
