import React from "react"
import * as styles from "./skip-to-content.module.css"

const SkipToContent = ({ mainId = "main" }) => (
  <a className={styles.skipToContent} href={"#" + mainId}>
    Skip to content
  </a>
)

export default SkipToContent
