import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./press.module.css"
import Icon from "../../components/Icon"

export const Press = ({ slice }) => {
  const { heading, copy } = slice?.primary || {}

  return (
    <section data-color-scheme="scheme-1">
      <div className="page-width padding-section-vertical">
        <div className={styles.copy}>
          {heading && (
            <PrismicRichText
              field={heading.richText}
              components={{
                heading2: ({ children }) => <h2 className="h2 text-center">{children}</h2>,
              }}
            />
          )}
          {copy && <PrismicRichText field={copy.richText} />}
        </div>
        <div>
          <ul className={"flex-grid " + styles.list}>
            {slice.items?.map(({ press_link, press_logo }) => (
              <li key={press_logo.url}>
                {press_link?.url ? (
                  <a
                    href={press_link.url}
                    className={styles.logoWrapper}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GatsbyImage
                      image={press_logo.gatsbyImageData}
                      alt={press_logo.alt}
                      objectFit="contain"
                      className={"company-logo " + styles.logo}
                    />
                    <span className={styles.pressLink}>
                      Read the article <Icon name="external" size=".8em" />
                    </span>
                  </a>
                ) : (
                  <GatsbyImage
                    image={press_logo.gatsbyImageData}
                    alt={press_logo.alt}
                    objectFit="contain"
                    className={"company-logo " + styles.logo}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPress on PrismicHomepageDataBodyPress {
    primary {
      heading {
        richText
      }
      copy {
        richText
      }
    }
    items {
      press_link {
        url
      }
      press_logo {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
    }
  }
`
