import React from "react"
import * as Select from "@radix-ui/react-select"
import Icon from "../Icon"
import * as styles from "./form-select.module.css"

const FormSelect = ({
  value,
  onValueChange,
  availableValues,
  id,
  placeholder,
}) => {
  return (
    <Select.Root value={value} onValueChange={onValueChange}>
      <Select.Trigger className={styles.trigger} id={id}>
        <Select.Value placeholder={placeholder} />
        <Select.Icon>
          <Icon name="chevron" />
        </Select.Icon>
      </Select.Trigger>
      <Select.Content className={styles.content}>
        <Select.Viewport>
          {availableValues.map((val, i) => (
            <Select.Item key={i} value={val} className={styles.item}>
              <Select.ItemText>{val}</Select.ItemText>
            </Select.Item>
          ))}
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  )
}

export default FormSelect
