import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { motion, AnimatePresence } from "framer-motion"
import Icon from "../../components/Icon"
import * as styles from "./ecomm-showcase.module.css"

export const EcommShowcase = ({ slice }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const motionVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  }

  const { heading, copy } = slice?.primary || {}

  return (
    <section id="products">
      <div className="section-split-6-5 page-width padding-section-vertical">
        <div>
          {heading && (
            <PrismicRichText
              field={heading.richText}
              components={{
                heading2: ({ children }) => <h2 className="h1">{children}</h2>,
              }}
            />
          )}
          {copy && <PrismicRichText field={copy.richText} />}
        </div>
        <div className={styles.screenshots}>
          <div className={styles.screenshotContainer}>
            <AnimatePresence mode="wait">
              <motion.div
                key={slice.items[currentIndex]?.brand_name.text}
                variants={motionVariants}
                className={styles.screenshotWrapper}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.35, ease: "easeInOut" }}
              >
                <GatsbyImage
                  id="store-image"
                  image={
                    slice.items[currentIndex]?.featured_image.gatsbyImageData
                  }
                  className={styles.screenshotImage}
                  alt={slice.items[currentIndex]?.featured_image.alt}
                />
                {slice.items[currentIndex]?.store_link.url && (
                  <a className={styles.storeLink} href={slice.items[currentIndex]?.store_link.url} target="_blank" rel="noreferrer">
                    Shop <Icon name="external" size=".9em"/>
                  </a>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
          <div
            className={styles.brands + " flex-grid"}
            style={{ "--columns": `${slice.items?.length}` }}
          >
            {slice.items?.map(({ brand_logo, brand_name }, index) => (
              <button
                aria-controls="store-image"
                className={styles.brandButton}
                key={brand_name.text}
                onClick={() => setCurrentIndex(index)}
                data-current={
                  brand_name.text === slice.items[currentIndex]?.brand_name.text
                }
              >
                <GatsbyImage
                  image={brand_logo.gatsbyImageData}
                  className={styles.brandLogo}
                  alt={brand_name.text}
                  width={150}
                  height={100}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyEcommShowcase on PrismicHomepageDataBodyEcommShowcase {
    primary {
      heading {
        richText
      }
      copy {
        richText
      }
    }
    items {
      brand_logo {
        gatsbyImageData(placeholder: BLURRED)
        alt
      }
      brand_name {
        text
      }
      featured_image {
        gatsbyImageData(placeholder: BLURRED)
        alt
      }
      store_link {
        url
      }
    }
  }
`
