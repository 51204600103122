import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { motion, AnimatePresence } from "framer-motion"

import {
  ActiveSlideProvider,
  useActiveSlide,
} from "../../context/active-slide-context"
import PhoneCarousel from "../../components/PhoneCarousel"
import SocialVideo from "../../components/SocialVideo"
import SocialImage from "../../components/SocialImage"

import * as styles from "./collabs.module.css"

const PartnerLogos = ({ slice }) => {
  const { activeIndex } = useActiveSlide()

  const motionVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  }

  return (
    <div className={styles.brands}>
      <div className={styles.brandsInner}>
        <AnimatePresence mode="wait">
          <motion.img
            key={slice.items[activeIndex]?.partner_logo.url}
            src={slice.items[activeIndex]?.partner_logo.url}
            alt=""
            width={1400}
            height={1000}
            variants={motionVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.25, ease: "easeInOut" }}
          />
        </AnimatePresence>
      </div>
    </div>
  )
}

export const Collabs = ({ slice }) => {
  const { heading, copy } = slice?.primary || {}

  return (
    <ActiveSlideProvider>
      <section
        data-color-scheme="scheme-6"
        style={{ position: "relative", isolation: "isolate" }}
        id="collabs"
      >
        <div className="section-split-6-5 page-width padding-section-vertical">
          <div className={styles.content}>
            {heading && (
              <PrismicRichText
                field={heading.richText}
                components={{
                  heading2: ({ children }) => (
                    <h2 className="h2">{children}</h2>
                  ),
                  strong: ({ children }) => <small>{children}</small>,
                }}
              />
            )}
            {copy && <PrismicRichText field={copy.richText} />}
          </div>
          <div className={styles.carousel}>
            <PhoneCarousel>
              {slice.items?.map(
                ({ video, image, partner_logo, collab_info }, index) => {
                  if (video) {
                    return (
                      <SocialVideo
                        key={video.url}
                        index={index}
                        url={video.url}
                      >
                        {partner_logo && (
                          <GatsbyImage
                            image={partner_logo.gatsbyImageData}
                            alt={partner_logo.alt}
                            className={styles.brandLogoSmall}
                          />
                        )}
                        {collab_info && (
                          <PrismicRichText field={collab_info.richText} />
                        )}
                      </SocialVideo>
                    )
                  } else if (image) {
                    return (
                      <SocialImage
                        image={image.gatsbyImageData}
                        alt={image.alt}
                        key={image.url}
                      >
                        {partner_logo && (
                          <GatsbyImage
                            image={partner_logo.gatsbyImageData}
                            alt={partner_logo.alt}
                            className={styles.brandLogoSmall}
                          />
                        )}
                        {collab_info && (
                          <PrismicRichText
                            field={collab_info.richText}
                            components={{
                              paragraph: ({ children }) => (
                                <p className={styles.videoInfo}>{children}</p>
                              ),
                            }}
                          />
                        )}
                      </SocialImage>
                    )
                  }
                  return null
                }
              )}
            </PhoneCarousel>
          </div>
        </div>
        <PartnerLogos slice={slice} />
      </section>
    </ActiveSlideProvider>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCollabs on PrismicHomepageDataBodyCollabs {
    primary {
      heading {
        richText
      }
      copy {
        richText
      }
    }
    items {
      video {
        id
        url
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
      partner_logo {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
      collab_info {
        richText
      }
    }
  }
`
