import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"

import PhoneCarousel from "../../components/PhoneCarousel"
import SocialVideo from "../../components/SocialVideo"
import Icon from "../../components/Icon"
import { ActiveSlideProvider } from "../../context/active-slide-context"

import * as styles from "./social-videos.module.css"

const StatsWidget = () => {
  return (
    <div className={styles.statsWidget}>
      <Icon name="speechBubble" size="1.5em" />
      <span>3020</span>
      <Icon name="heart" size="1.5em" />
      <span>343,904</span>
    </div>
  )
}

export const SocialVideos = ({ slice }) => {
  const { heading, copy } = slice?.primary || {}

  return (
    <section data-color-scheme="scheme-3" id="content">
      <div className="section-split-6-5 page-width padding-section-vertical">
        <div className={styles.copy}>
          {heading && (
            <PrismicRichText
              field={heading.richText}
              components={{
                heading2: ({ children }) => <h2 className="h1">{children}</h2>,
                strong: ({ children }) => <small>{children}</small>,
              }}
            />
          )}
          {copy && <PrismicRichText field={copy.richText} />}
        </div>
        <div className={styles.carousel}>
          <ActiveSlideProvider>
            <PhoneCarousel>
              {slice.items?.map(
                ({ video }, index) =>
                  video && (
                    <SocialVideo key={video.id} index={index} url={video.url} />
                  )
              )}
            </PhoneCarousel>
            <StatsWidget />
          </ActiveSlideProvider>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodySocialVideos on PrismicHomepageDataBodySocialVideos {
    primary {
      heading {
        richText
      }
      copy {
        richText
      }
    }
    items {
      video {
        id
        url
      }
    }
  }
`
